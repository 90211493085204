import './App.css';
import React, { useEffect, Suspense, lazy } from "react";
// import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastProvider } from "react-toast-notifications";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import Accordion from 'react-bootstrap/Accordion'
import 'animate.css';
import Dashboard from './components/Dashboard';

function getFaviconEl() {
  return document.getElementById("favicon");
}

function App() {
  const merchantData = useSelector((state) => state.auth.merchantData);

  useEffect(() => {
    const favicon = getFaviconEl(); // Accessing favicon element
    if(favicon) {
    favicon.href = merchantData?.favIconUrl;
    }
  }, [merchantData]);
  
  return (
    <ToastProvider placement="bottom-left" autoDismiss={true} autoDismissTimeout="2000">
      <Router>
       
            <Routes>
              <Route exact path="/" element={<Dashboard/>} />
              {/* <Route exact path="/receipt" element={<Receipt/>} />
              <Route exact path="/not-found" element={<NotFound />} /> */}
              <Route path="*" element={<Dashboard />} />
            </Routes>
      </Router>
  </ToastProvider>
  );
}

export default App;
