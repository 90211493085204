import React, { useEffect, useState } from 'react';
import axios from "axios";
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import './style.scss';
import Auth from './components/Auth';
import Cart from './components/Cart';
import Payment from './components/Payment';
import Shipping from './components/Shipping';
import { animateCSS } from '../../helpers/utils';
import { API_URL, headerOptions } from "../../config/config";
import { getMerchant, getDiscount, guestEmailLogin, getCheckoutData } from "../../services/authServices";
import {
   addUsrDetails,
   addUserCards,
   addProduct,
   addCart,
   addMerchantURL,
   addMerchant,
   addUsrAddress,
   addUserShipAddress,
   updateCheckoutFormValues,
   shippingOptionLoader,
   addShippingOption,
   addUserShipAddressList,
   addPossibleAddress,
   selectedShippingOption,
   checkoutDataDetails
} from '../../redux/actions/authActions';
import { getRandom } from "../../helpers/utils";
import { Link } from "react-router-dom";
var Buffer = require('buffer/').Buffer;


function Home(props) {
   const { setType, setReceiptData, setLogo, logo } = props;
   const dispatch = useDispatch();
   const { addToast } = useToasts();
   const urlSearchParams = new URLSearchParams(useLocation().search).get('data');
   const userInfo = useSelector((state) => state.auth.userInfo);
   const cartProductsReducer = useSelector((state) => state.auth.products);
   const emptyCartReducer = useSelector((state) => state.auth.emptyCart);
   const userAddress = useSelector((state) => state.auth.userAddress);
   const userShippingAddress = useSelector((state) => state.auth.userShippingAddress);
   const userSaveCard = useSelector((state) => state.auth.userSaveCard);
   const merchantUrlData = useSelector((state) => state.auth.merchantUrlData);
   const merchantData = useSelector((state) => state.auth.merchantData);
   const checkoutDetails = useSelector((state) => state.auth.checkoutData);
   const cart = useSelector((state) => state.auth.cart);

   const [shippingOptionSelect, setShippingOptionSelect] = useState({});
   const [isShipDiffrentAddress, setIsShipDiffrentAddress] = useState(false);
   const [totalDiscount, setTotalDiscount] = useState(0);
   const [taxTotal] = useState(0);
   const [shippingTotal, setShippingTotal] = useState(0);
   const [cartCount, setCartCount] = useState(0);
   const [cartTotal, setCartTotal] = useState(0);
   const [submitLoader, setSubmitLoader] = useState(false);
   const [cardSelectionChanged, onCardSelectionChanged] = useState({});
   const [payWithNewCard, setPayWithNewCard] = useState(true);
   const [email, setEmail] = useState('');
   const [cvv, setCvv] = useState('');
   const [checkout, setCheckout] = useState(true);

   const loginType = Object.keys(merchantUrlData).length && merchantUrlData.decodeUser.loginType

   const setupSpreedly = (cartItems) => {
      window.Spreedly.init("JX1JC8nP7RWJfi6gCyxodWLhAo9", {
         numberEl: "spreedly-number",
         cvvEl: "spreedly-cvv",
      })
      window.Spreedly.on(
         "fieldEvent",
         function (name, type, activeEl, inputProperties) {
            let isReload = false
            if (type === "tab" || type === "enter" || type === "blur") {
               let cardNumberInputLength = localStorage.getItem("numberCount")
               if (
                  Number(cardNumberInputLength) !== 16 &&
                  Number(cardNumberInputLength) !== 15 &&
                  Number(cardNumberInputLength) !== null &&
                  Number(cardNumberInputLength) !== 0
               ) {
                  animateCSS("spreedly-number", "shakeX")
               }
            }
            if (type === "input") {
               if (name === "number") {
                  let number = inputProperties["numberLength"]
                  //if card value enter then null month, year and cvv currently cvv not null
                  if (number === 16 && inputProperties["cardType"] === "visa") {
                     document.getElementById("month").focus()
                  } else if (
                     number === 16 &&
                     inputProperties["cardType"] === "master"
                  ) {
                     document.getElementById("month").focus()
                  } else if (
                     number === 16 &&
                     inputProperties["cardType"] === "discover"
                  ) {
                     document.getElementById("month").focus()
                  } else if (
                     number === 15 &&
                     inputProperties["cardType"] === "american_express"
                  ) {
                     document.getElementById("month").focus()
                  } else {
                  }
                  if (number === 0) {
                     handleChange('userInfo', 'cardMonth', '');
                     handleChange('userInfo', 'cardYear', '');
                     isReload = true
                     window.Spreedly.reload()
                  }
               }
               if (name === "cvv") {
                  window.Spreedly.setStyle("number", "color:red;")
                  let numberCvv = inputProperties["cvvLength"]
                  //check card no then set cvv Digit
                  if (inputProperties["numberLength"] === "") {
                     window.Spreedly.resetFields("cvv")
                     addToast("Please Enter Card Number", {
                        appearance: "error",
                     })
                  } else if (
                     inputProperties["cardType"] !== "american_express" &&
                     numberCvv > 3
                  ) {
                     addToast("Please Enter Proper CVV number", {
                        appearance: "error",
                     })
                     window.Spreedly.resetFields("cvv")
                     window.Spreedly.reload()
                  } else if (
                     inputProperties["cardType"] !== "american_express" &&
                     numberCvv === 3
                  ) {
                     document.getElementById("full_name").focus()
                  } else if (
                     inputProperties["cardType"] === "american_express" &&
                     numberCvv === 4
                  ) {
                     document.getElementById("full_name").focus()
                  } else {
                  }
               }
               if (!isReload) {
                  let number = inputProperties["numberLength"]
                  localStorage.setItem(
                     "numberCount",
                     inputProperties["numberLength"]
                  )
                  if (inputProperties["cvvLength"] === 0 && userInfo?.cardMonth && userInfo?.cardYear) {
                     handleChange('userInfo', 'cardMonth', '');
                     handleChange('userInfo', 'cardYear', '');
                  }
                  if (inputProperties["cardType"] === "visa") {
                     if (number > 16) {
                        window.Spreedly.resetFields("number")
                        addToast("VISA card number should be 16 digit", {
                           appearance: "error",
                        })
                     }
                  }
                  if (
                     inputProperties["cardType"] === "visa" ||
                     inputProperties["cardType"] === "master" ||
                     inputProperties["cardType"] === "discover"
                  ) {
                     if (number === 16) {
                        window.Spreedly.setStyle("number", "color:black;")
                     }
                  }
                  if (inputProperties["cardType"] === "master") {
                     if (number === 17) {
                        window.Spreedly.setStyle("number", "color:black;")
                     }
                  }
                  if (inputProperties["cardType"] === "american_express") {
                     let number = inputProperties["numberLength"]
                     if (number === 15) {
                        window.Spreedly.setStyle("number", "color:black;")
                     }
                  }
                  if (type === "input") {
                     localStorage.setItem(
                        "cvvCount",
                        inputProperties["cvvLength"]
                     )
                     if (
                        inputProperties["cardType"] !== "american_express" &&
                        inputProperties["cvvLength"] !== 3
                     ) {
                     } else if (
                        inputProperties["cardType"] === "american_express" &&
                        inputProperties["cvvLength"] !== 4
                     ) {
                     } else {
                        window.Spreedly.setStyle("cvv", "color:black;")
                     }
                  }
                  if (
                     type === "input" &&
                     inputProperties["cardType"] === "visa"
                  ) {
                     document.getElementById("cardLogoSet").src =
                        "https://wave-admin.s3.amazonaws.com/visa.svg"
                  } else if (
                     type === "input" &&
                     inputProperties["cardType"] === "master"
                  ) {
                     document.getElementById("cardLogoSet").src =
                        "https://wave-admin.s3.amazonaws.com/mastercard.svg"
                  } else if (
                     type === "input" &&
                     inputProperties["cardType"] === "discover"
                  ) {
                     document.getElementById("cardLogoSet").src =
                        "https://wave-admin.s3.amazonaws.com/discover.svg"
                  } else if (
                     type === "input" &&
                     inputProperties["cardType"] === "american_express"
                  ) {
                     document.getElementById("cardLogoSet").src =
                        "https://wave-admin.s3.amazonaws.com/amx.svg"
                  } else {
                  }
               } else {
                  document.getElementById("cardLogoSet").src =
                     "https://wave-admin.s3.amazonaws.com/creditcard.svg"
               }
            }
         }
      )
      window.Spreedly.on("ready", function () {
         const submitButton = document.getElementById("submit-button")
         submitButton.disabled = false
         window.Spreedly.setParam("allow_blank_name", true)
         window.Spreedly.setParam("allow_expired_date", true)
         // credit card number
         window.Spreedly.setPlaceholder("number", "Card Number")
         window.Spreedly.setFieldType("number", "text")
         window.Spreedly.setStyle("number", "font-size: 14px; padding-top: 4px; padding-left: 35px; width: 100%")

         // cvv
         window.Spreedly.setPlaceholder("cvv", "CVV")
         window.Spreedly.setFieldType("cvv", "number")
         window.Spreedly.setStyle("cvv", "font-size: 14px; line-height: 24px;")

         // full_name
         window.Spreedly.setPlaceholder("full_name", "Cardholder name")
         window.Spreedly.setFieldType("full_name", "text")
         window.Spreedly.setStyle(
            "full_name",
            "font-size: 14px; text-transform:capitalize;  padding: 8px;"
         )
         // address1
         window.Spreedly.setPlaceholder("address1", "Address 1")
         window.Spreedly.setFieldType("address1", "text")
         window.Spreedly.setStyle("address1", "font-size: 14px; padding: 8px;")
         // address2
         window.Spreedly.setPlaceholder("address2", "Address 2")
         window.Spreedly.setFieldType("address2", "text")
         window.Spreedly.setStyle("address2", "font-size: 14px; padding: 8px;")
         window.Spreedly.setNumberFormat("prettyFormat")
         window.Spreedly.setParam()
      })
      window.Spreedly.on("errors", (errors) => {
         var error
         for (let i = 0; i < errors.length; i++) {
            error = errors[i]
         }
         setSubmitLoader(false);
         addToast(errors[0].attribute + " " + errors[0].message, {
            appearance: "error",
         })

         window.Spreedly.on(
            "fieldEvent",
            function (name, event, activeElement, inputData) {
               if (event === "input") {
                  if (inputData["validCvv"]) {
                     window.Spreedly.setStyle(
                        "cvv",
                        "background-color: #CDFFE6;"
                     )
                  } else {
                     window.Spreedly.setStyle(
                        "cvv",
                        "background-color: #FFFFFF;"
                     )
                  }
                  if (inputData["validNumber"]) {
                     window.Spreedly.setStyle(
                        "number",
                        "background-color: #CDFFE6;"
                     )
                  } else {
                     window.Spreedly.setStyle(
                        "number",
                        "background-color: #FFFFFF;"
                     )
                  }
               }
            }
         )
         window.Spreedly.reload()
         document.getElementById("month").value = ""
         document.getElementById("year").value = ""
         handleChange('userInfo', 'cardMonth', '');
         handleChange('userInfo', 'cardYear', '');
      })
      window.Spreedly.on("paymentMethod", async (token, paymentMethod) => {
         if (
            localStorage.getItem("userType") === "0"
         ) {
            let sessionToken = localStorage.getItem("sessionToken");
            if (!sessionToken) {
               addToast("Invalid Session Token", { appearance: "error" })
               return false
            }
            paymentMethod.month =
               paymentMethod.month < 10
                  ? "0" + paymentMethod.month
                  : paymentMethod.month
            axios
               .post(
                  `${API_URL.url}/card/tokenize`,
                  {
                     cardDetails: {
                        cardType: paymentMethod.card_type,
                        cardNumber: paymentMethod.number,
                        expiryMonth: paymentMethod.month,
                        expiryYear: paymentMethod.year,
                        cardReference: paymentMethod.token,
                        nameOnCard: paymentMethod.full_name,
                        nickName: paymentMethod.last_name,
                        apikey: API_URL.apikey,
                     },
                  },
                  {
                     auth: {
                        username: `sessionToken`,
                        password: `${sessionToken}`,
                     },
                  }
               )
               .then((responseFirst) => {
                  if (responseFirst.data.error === "Invalid User") {
                     addToast("Invalid User", { appearance: "error" });
                     setSubmitLoader(false);
                  } else {
                     let fundExternalId = responseFirst.data.fundExternalId
                     let itemList = []
                     let cartProducts = cartItems// cartProducts.cartItems//cartData
                     cartProducts.forEach((cartItemList, key) => {
                        itemList.push({
                           productId: cartItemList.productId,
                           quantity: cartItemList.quantity,
                        })
                     })
                     axios
                        .post(
                           `${API_URL.url}/checkout`,
                           {
                              items: itemList,
                           },
                           {
                              auth: {
                                 username: `sessionToken`,
                                 password: `${sessionToken}`,
                              },
                           }
                        )
                        .then((responseSecond) => {
                           let transaction_ID =
                              responseSecond.data.transaction.transactionId
                           axios
                              .post(
                                 `${API_URL.url}/transaction/${transaction_ID}/approve`,
                                 {
                                    userType: "register",
                                    token: `${fundExternalId}`,
                                    rememberMe: "false",
                                 },
                                 {
                                    auth: {
                                       username: `sessionToken`,
                                       password: `${sessionToken}`,
                                    },
                                 }
                              )
                              .then((responseThird) => {
                                 if (responseThird.data.success) {
                                    addToast("Payment completed", {
                                       appearance: "success",
                                    })
                                    setType(0);
                                    setReceiptData({
                                       urlOrderID: responseThird.data.transaction.orderId,
                                       urlOrderNo: responseThird.data.transaction.orderNumber,
                                       transactionID: transaction_ID,
                                       shipping: `${shippingTotal.toFixed(2)}`,
                                       tax: `${taxTotal.toFixed(2)}`,
                                       total: `${cartTotal.toFixed(2)}`
                                    })
                                 } else {
                                    addToast("Invalid User", {
                                       appearance: "error",
                                    })
                                    setSubmitLoader(false);
                                 }
                              })
                              .catch((err) => {
                                 setSubmitLoader(false);
                                 console.log("Failure Approve api", err)
                              })
                        })
                        .catch(() => {
                           setSubmitLoader(false);
                           addToast("Transaction Failure", {
                              appearance: "error",
                           })
                        })
                  }
               })
               .catch((err) => {
                  setSubmitLoader(false);
                  console.log("Tokenize Failure API", err)
               })
         } else if (
            localStorage.getItem("userType") === "1"
         ) {
            paymentMethod.month =
               paymentMethod.month < 10
                  ? "0" + paymentMethod.month
                  : paymentMethod.month
            let sessionToken = localStorage.getItem("sessionToken");

            if (!sessionToken) {
               addToast("Invalid Session Token", { appearance: "error" })
               return false
            } else {
               axios
                  .post(
                     `${API_URL.url}/card/tokenize`,
                     {
                        cardDetails: {
                           cardType: paymentMethod.card_type,
                           cardNumber: paymentMethod.number,
                           expiryMonth: paymentMethod.month,
                           expiryYear: paymentMethod.year,
                           cardReference: paymentMethod.token,
                           nameOnCard: paymentMethod.full_name,
                           nickName: paymentMethod.last_name,
                           apikey: API_URL.apikey,
                        },
                     },
                     {
                        auth: {
                           username: `sessionToken`,
                           password: `${sessionToken}`,
                        },
                     }
                  )
                  .then((responseFirst) => {
                     if (responseFirst.data.error === "Invalid User") {
                        setSubmitLoader(false);
                        addToast("Invalid User", { appearance: "error" })
                     } else {
                        let fundExternalId = responseFirst.data.fundExternalId
                        let itemList = []
                        let cartProducts = cartItems // cartProducts.cartItems//cartData
                        cartProducts.forEach((cartItemList, key) => {
                           itemList.push({
                              productId: cartItemList.productId,
                              quantity: cartItemList.quantity,
                           })
                        })
                        axios
                           .post(
                              `${API_URL.url}/checkout`,
                              { items: itemList },
                              {
                                 auth: {
                                    username: `sessionToken`,
                                    password: `${sessionToken}`,
                                 },
                              }
                           )
                           .then((responseSecond) => {
                              let transaction_ID =
                                 responseSecond.data.transaction.transactionId
                              axios
                                 .post(
                                    `${API_URL.url}/transaction/${transaction_ID}/approve`,
                                    {
                                       userType: "register",
                                       token: `${fundExternalId}`,
                                       rememberMe: "true",
                                    },
                                    {
                                       auth: {
                                          username: `sessionToken`,
                                          password: `${sessionToken}`,
                                       },
                                    }
                                 )
                                 .then((responseThird) => {
                                    if (responseThird.data.success) {
                                       addToast("Payment completed", {
                                          appearance: "success",
                                       })
                                       setType(0);
                                       setReceiptData({
                                          urlOrderID: responseThird.data.transaction.orderId,
                                          urlOrderNo: responseThird.data.transaction.orderNumber,
                                          transactionID: transaction_ID,
                                          shipping: `${shippingTotal.toFixed(2)}`,
                                          tax: `${taxTotal.toFixed(2)}`,
                                          total: `${cartTotal.toFixed(2)}`
                                       })
                                    } else {
                                       setSubmitLoader(false);
                                       addToast("Invalid User", {
                                          appearance: "error",
                                       })
                                    }
                                 })
                                 .catch(() => {
                                    setSubmitLoader(false);
                                 })
                           })
                           .catch((err) => {
                              setSubmitLoader(false);
                              addToast("Transaction Failure", {
                                 appearance: "error",
                              })
                           })
                     }
                  })
                  .catch((err) => {
                     setSubmitLoader(false);
                     console.log("Tokenize Failure API", err)
                  })
            }
         } else {
            addToast("User type not matched", { appearance: "error" })
         }
      })
      window.Spreedly.on("consoleError", function (error) {
         console.log("Error from Spreedly iFrame: " + error["msg"])
      })
   }

   useEffect(() => {
      if ((cartProductsReducer.length === 0 && emptyCartReducer) || (cartProductsReducer.length > 0 && !emptyCartReducer)) {
         setTimeout(() => {
            if (window.Spreedly) {
               setupSpreedly(cartProductsReducer);
            }
         }, 500);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [cartProductsReducer]);

   useEffect(() => {
      dispatch(addUsrDetails(JSON.parse(localStorage.getItem("loggedInUserData"))));
      dispatch(addUserCards(JSON.parse(localStorage.getItem("loggedInUser"))?.fundExternals));
      dispatch(addUsrAddress(JSON.parse(localStorage.getItem("loggedInUser"))?.address));
      dispatch(addUserShipAddress(JSON.parse(localStorage.getItem("loggedInUser"))?.shippingAddress));
      if (localStorage.getItem("loggedInUser") && localStorage.getItem("loggedInUser").address) {
         getShippingInfo(JSON.parse(localStorage.getItem("loggedInUser"))?.address, JSON.parse(localStorage.getItem("loggedInUserData")));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

   useEffect(() => {
      dispatch(selectedShippingOption(shippingOptionSelect));
   }, [shippingOptionSelect])

   useEffect(() => {
      if (loginType === 'Guest') {
         setPayWithNewCard(true)
      }
   }, [loginType])

   // GET SHIPPING API's
   const getShippingInfo = (address, userDetail) => {
      dispatch(shippingOptionLoader(true));
   };

   // UPDATE AUTH REDUCER
   const updateAuthReducer = (responseData, address = null, decodeUser = null, decodeShippingRate) => {
      let userInfo = {
         "userId": responseData?.user?.userId,
         "email": responseData?.user?.email,
         "name": responseData?.user?.name,
         "firstname": responseData?.user?.firstname,
         "lastname": responseData?.user?.lastname,
         "phoneNumber": responseData?.user?.phoneNumber,
         "isPhoneVerified": responseData?.user?.isPhoneVerified,
         "hasAddress": responseData?.user?.hasAddress,
         "pendingTransactions": responseData?.user?.pendingTransactions,
         "pendingAuthentications": responseData?.user?.pendingAuthentications,
         "url": responseData?.user?.url,
         "imageUrl": responseData?.user?.imageUrl
      }
      localStorage.setItem(
         "loggedInUserData",
         JSON.stringify(userInfo)
      );
      dispatch(addUsrDetails(userInfo));
      dispatch(addUsrAddress(address ? address : responseData.user.address));
      localStorage.setItem(
         "loggedInUser",
         JSON.stringify(responseData.user)
      );
      dispatch(addUserCards(responseData.user && responseData.user.fundExternals ? responseData.user.fundExternals : ''));
      dispatch(addUserShipAddress(responseData.user && responseData.user.shippingAddress ? responseData.user.shippingAddress : ''));
      if (decodeUser && decodeUser.address.shipping && decodeUser.address.shipping.length > 0) {
         dispatch(addUserShipAddressList(decodeUser.address.shipping));
      } else {
         dispatch(addUserShipAddressList(responseData.user && responseData.user.shippingAddressList ? responseData.user.shippingAddressList : []));
      }
      dispatch(addPossibleAddress(responseData.user && responseData.user.possibleAddress ? responseData.user.possibleAddress : ''));
      if (decodeShippingRate && decodeShippingRate.length > 0) {
         setTimeout(() => {
            dispatch(addShippingOption(decodeShippingRate));
         }, 1500);
      } else {
         if (responseData.user &&
            responseData.user.shippingAddress &&
            responseData.user.shippingAddress.addressLine1 &&
            responseData.user.shippingAddress.cityName &&
            responseData.user.shippingAddress.postalCode) {
            getShippingInfo(userInfo, responseData.user.shippingAddress, cartProductsReducer);
         } else {
            if (responseData.user &&
               responseData.user.address &&
               responseData.user.address.addressLine1 &&
               responseData.user.address.cityName &&
               responseData.user.address.postalCode) {
               getShippingInfo(userInfo, responseData.user.address, cartProductsReducer);
            }
         }
      }
   }

   // LOGIN WITH GUEST USER
   const guestEmailLoginAPI = (decodeMerchant, decodeUser, decodeShippingRate) => {
      let FName = decodeUser.firstName ? decodeUser.firstName : decodeUser.mobile.substring(0, 5);
      let LName = decodeUser.lastName ? decodeUser.lastName : decodeUser.mobile.substring(5, 10);
      let generatedEmail = decodeUser.loginType.toLowerCase() === 'email' ? decodeUser.identity : typeof decodeUser.identity == 'number'  ?  decodeUser.identity + `@kachyng.net` : decodeUser.identity
      // let ProviderId = decodeUser?.requestProviderId;
      let ProviderId = getRandom(16);
      let authProvider = decodeUser?.requestProvider;
      let userParams = {
         email: generatedEmail,
         authProvider: authProvider,
         authProviderId: ProviderId,
         deviceVendor: "",
         deviceRegistrationId: "",
         firstname: FName,
         lastname: LName,
         deviceOsVersion: "",
         deviceName: "",
         deviceId: "",
         profileImageURL: "",
         mobileAppVersion: "",
      }
      guestEmailLogin(headerOptions, userParams)
         .then(function (response) {
            if (response.data && response.data.success) {
               if (cartProductsReducer.length > 0) {
                  getDiscountAPI(cartProductsReducer, response.data.sessionToken);
               }
               localStorage.removeItem("sessionToken");
               localStorage.removeItem("mobileNo");
               localStorage.removeItem("userType");
               localStorage.setItem("sessionToken", response.data.sessionToken);
               localStorage.setItem("mobileNo", response?.data?.user?.phoneNumber);
               localStorage.setItem("userType", 0);
               let userAddress = decodeUser.address.billing;
               //userAddress.cityName = userAddress.city;
               updateAuthReducer(response.data, userAddress, decodeUser, decodeShippingRate);
            } else {
               addToast("Failure guest login", {
                  appearance: "error",
               });
            }
         })
         .catch(function (error) {
            addToast("Something went wrong, please try again", {
               appearance: "error",
            });
         });
   }

   // GET MERCHANT API
   const getMerchantAPI = (merchantId, decodeMerchant, decodeUser) => {
      getMerchant(merchantId)
         .then(function (response) {
            if (response.status === 200) {
               if (!response?.data?.webstoreLogin) {
                  response.data.webstoreLogin = 'y';
               }
               if (response.data && response.data.storeLogoUrl) {
                  setLogo(response.data.storeLogoUrl);
               }
               dispatch(
                  addMerchant(response.data)
               );
               const webstoreLogin = response?.data?.webstoreLogin.toLowerCase();
               if (webstoreLogin === 'n' || webstoreLogin === 'false') {
                  guestEmailLoginAPI(decodeMerchant, decodeUser);
               }

            }
         })
         .catch(function (error) {
            console.log("error", error);
         });
   }

   // GET DISCOUNT API
   const getDiscountAPI = (cart, sessionToken) => {
      const productArr = [];
      if (cart.length > 0) {
         cart.forEach(element => {
            let proObj = {};
            proObj.productId = element.prductId;
            proObj.quantity = element.quantity;
            productArr.push(proObj);
         });
      }
      const params = {
         "products": productArr
      }
      getDiscount(params, sessionToken)
         .then(function (response) {
            if (response.data && response.data.success) {
               if (Number(response.data.discounts.user.actualDiscount) > 0) {
                  setTotalDiscount(Number(response.data.discounts.user.actualDiscount));
               }
            }
         })
         .catch(function (error) {
            console.log("error", error);
         });
   }

   const handleChange = (stateName, name, value) => {
      dispatch(updateCheckoutFormValues([stateName, { [name]: value }]));
   }

   // SUBMIT CHECKOUT PAY
   const submitCheckout = () => {
      if (payWithNewCard || Object.keys(cardSelectionChanged).length === 0 || userSaveCard.length === 0) {
         let flag = true;
         if (!localStorage.getItem("sessionToken")) {
            animateCSS("mobile", "shakeX");
            return false;
         } else if ((localStorage.getItem("userType") === "1") && (!userInfo.phoneNumber || userInfo.phoneNumber === '')) {
            animateCSS("mobile", "shakeX");
            flag = false
         }
         else if ((localStorage.getItem("userType") === "1") && (userInfo.phoneNumber && userInfo.phoneNumber.length < 12)) {
            animateCSS("mobile", "shakeX");
            flag = false
         } else {
            // if (email === '') {
            //    animateCSS("email", "shakeX");
            //    flag = false
            // }
            // var regEmail = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
            // if (!regEmail.test(email)) {
            //    animateCSS("email", "shakeX")
            //    flag = false
            // }
            if (!userInfo.name || userInfo.name === '') {
               animateCSS("full_name", "shakeX");
               flag = false
            }
            var regName = /^(([A-Za-z]+[-']?)*([A-Za-z]+)?\s)+([A-Za-z]+[-']?)*([A-Za-z]+)?$/
            if (!regName.test(userInfo.name)) {
               animateCSS("full_name", "shakeX")
               flag = false
            }
            if ((!userAddress) || (!userAddress.addressLine1 || userAddress.addressLine1 === '')) {
               animateCSS("streetlist", "shakeX");
               flag = false
            }
            if ((isShipDiffrentAddress) && (!userInfo.name || userInfo.name === '')) {
               animateCSS("shippingName", "shakeX");
               flag = false
            }
            if ((isShipDiffrentAddress) && (!userShippingAddress.addressLine1 || userShippingAddress.addressLine1 === '')) {
               animateCSS("shipstreetlist", "shakeX");
               flag = false
            }
            window.Spreedly.validate()
            window.Spreedly.on("validation", function (inputProperties, type) {
               if (inputProperties["numberLength"] === 0) {
                  animateCSS("spreedly-number", "shakeX")
                  flag = false
               }
            });
            if (cvv.length === 0) {
               animateCSS("spreedly-cvv", "shakeX")
               flag = false
            }
            if (!userInfo.cardMonth || userInfo.cardMonth === '') {
               animateCSS("month", "shakeX");
               flag = false
            }
            if (!userInfo.cardYear || userInfo.cardYear === '') {
               animateCSS("year", "shakeX");
               flag = false
            }
         }

         if (flag) {
            let requiredFields = {};
            requiredFields["full_name"] = userInfo.name;
            requiredFields["address1"] = userAddress.addressLine1;
            requiredFields["city"] = userAddress.cityName;
            requiredFields["state"] = userAddress.state;
            requiredFields["zip"] = userAddress.postalCode;
            requiredFields["month"] = userInfo.cardMonth;
            requiredFields["year"] = userInfo.cardYear;
            requiredFields["metadata"] = localStorage.getItem("sessionToken"); // hard code sesssionToken
            requiredFields["phone_number"] = userInfo.mobile;
            window.Spreedly.tokenizeCreditCard(requiredFields);

            setSubmitLoader(true);
         }
      } else {
         let sessionToken = localStorage.getItem("sessionToken");
         let itemList = []
         let cartProducts = cartProductsReducer// cartProducts.cartItems//cartData
         cartProducts.forEach((cartItemList, key) => {
            itemList.push({
               productId: cartItemList.productId,
               quantity: cartItemList.quantity,
            })
         })
         setSubmitLoader(true);
         axios
            .post(
               `${API_URL.url}/checkout`,
               {
                  items: itemList,
               },
               {
                  auth: {
                     username: `sessionToken`,
                     password: `${sessionToken}`,
                  },
               }
            )
            .then((responseSecond) => {
               let transaction_ID =
                  responseSecond.data.transaction.transactionId
               axios
                  .post(
                     `${API_URL.url}/transaction/${transaction_ID}/approve`,
                     {
                        userType: "register",
                        token: `${cardSelectionChanged.fundExternalId}`,
                        rememberMe: "true",
                     },
                     {
                        auth: {
                           username: `sessionToken`,
                           password: `${sessionToken}`,
                        },
                     }
                  )
                  .then((responseThird) => {
                     if (responseThird.data.success) {
                        addToast("Payment completed", {
                           appearance: "success",
                        })
                        setType(0);
                        setReceiptData({
                           urlOrderID: responseThird.data.transaction.orderId,
                           urlOrderNo: responseThird.data.transaction.orderNumber,
                           transactionID: transaction_ID,
                           shipping: `${shippingTotal.toFixed(2)}`,
                           tax: `${taxTotal.toFixed(2)}`,
                           total: `${cartTotal.toFixed(2)}`
                        })
                     } else {
                        addToast("Invalid User", {
                           appearance: "error",
                        })
                        setSubmitLoader(false);
                     }
                  })
                  .catch((err) => {
                     setSubmitLoader(false);
                     console.log("Failure Approve api", err)
                  })
            })
            .catch(() => {
               setSubmitLoader(false);
               addToast("Transaction Failure", {
                  appearance: "error",
               })
            })
      }
   }

   const checkoutData = (checkoutId) => {
      checkout && getCheckoutData(checkoutId)
         .then(function (response) {
            if (response.status === 200) {
               dispatch(
                  checkoutDataDetails(response.data)
               );
               dispatch({ type: "CHECKOUT_DATA", data: response.data.payloadData });
            }
         })
         .catch(function (error) {
            console.log("error", error);
         });
   }

   useEffect(() => {
      if (urlSearchParams) {
         checkoutData(urlSearchParams)
         // let decodeCartData = Buffer.from(urlSearchParams, "base64").toString("ascii");
         // decodeCartData = JSON.parse(decodeCartData);
         if (checkoutDetails && checkout) {
            setCheckout(false)
            const decodeCartData = checkoutDetails
            if (decodeCartData.cart) {
               setCartCount(decodeCartData.cart.length);
               let cartTotalAmount = 0;
               let cart = decodeCartData.cart
               for (let int = 0; int < cart.length; int++)
               {
                  cartTotalAmount = (cartTotalAmount + cart[int].price )- (cart[int].discount ? cart[int].discount : 0)
               }
               setCartTotal(cartTotalAmount > 0 ? cartTotalAmount : 0);
               dispatch(addProduct(decodeCartData.cart));
               let decodeMerchant = decodeCartData.merchant;
               let decodeUser = decodeCartData.user;
               let decodeShippingRate = decodeCartData.shippingRate;
               getMerchantAPI(decodeCartData.merchant.id, decodeMerchant, decodeUser);
               dispatch(addMerchantURL({ decodeMerchant, decodeUser }));
               dispatch(addCart(decodeCartData.requester));
               if (localStorage.getItem("sessionToken")) {
                  getDiscountAPI(decodeCartData.cart, localStorage.getItem("sessionToken"));
               }
               if (decodeCartData.user && decodeCartData.user.loginType && (decodeCartData.user.loginType === "EMAIL" || decodeCartData.user.loginType === "Mobile" || decodeCartData.user.type === "UserId")) {
                  guestEmailLoginAPI(decodeMerchant, decodeUser, decodeShippingRate);
               }
            }
         }
      } else {
         dispatch(addProduct([]));
      }
   }, [dispatch, urlSearchParams, checkoutDetails]);

   return (
      <div className={`container pb-md-4 pb-2 ${logo ? '' : 'pt-md-4 mt-2 pt-2'}`} >
         <div className='row checkout-form'>
            <div className="col-12 col-lg-3 col-md-6 col-xs-12 small order-lg-1 desktop-view">
               <Cart
                  cartCount={cartCount}
                  cartTotal={cartTotal}
                  taxTotal={taxTotal}
                  shippingTotal={shippingTotal}
                  shippingOptionSelect={shippingOptionSelect}
                  totalDiscount={totalDiscount}
                  submitCheckout={submitCheckout}
                  submitLoader={submitLoader}
                  loginType={loginType}
               />
            </div>
            {(!merchantData["webstoreLogin"] || (merchantData["webstoreLogin"] === 'true' || merchantData["webstoreLogin"] === 'y')) &&
            checkout == true && !urlSearchParams  &&
               <div className="col-12 col-lg-3 col-md-6 col-xs-12 small">
                  <Auth
                     handleChange={handleChange}
                     setTotalDiscount={setTotalDiscount}
                  />
               </div>
            }
            {loginType != 0 ?
            <div className="col-12 col-lg-3 col-md-6 col-xs-12">
               <h5 className="mb-3"> Login </h5>
               {loginType === 'Guest' ?
                  <>
                     <label className="small-text">
                        <strong>Logged in as Guest</strong> <span className="required">*</span>
                        
                     </label>
                     <input
                        id="email"
                        name="email"
                        type="text"
                        placeholder="Email"
                        className="form-control"
                        autoComplete="off"
                        value={email}
                        onChange={(e) => {
                           setEmail(e.target.value);
                        }}
                     />
                  </>
                  :
                  <>
                     <label className="small-text">
                        {shippingTotal || cart.storeType !== 'Shopify' ? <><strong>Logged in as </strong> <span className="required">*</span></> : <Skeleton />}
                        <Link
                        to={`${merchantUrlData?.decodeMerchant?.logoutUrl ? merchantUrlData?.decodeMerchant?.logoutUrl : '/'}`}
                        onClick={(event) => {
                           event.preventDefault();
                           localStorage.clear();
                           window.location.href = merchantUrlData?.decodeMerchant?.logoutUrl ? merchantUrlData?.decodeMerchant?.logoutUrl : '/';
                        }}
                        >
                        {" "}
                        <span className='alignRight'>
                           {" "}
                           Logout{" "}
                        </span>{" "}
                     </Link>
                     </label>
                     {shippingTotal || cart.storeType !== 'Shopify' ?
                        <input
                           id="email"
                           name="email"
                           type="text"
                           placeholder="Email"
                           className="form-control"
                           autoComplete="off"
                           value={userInfo ? `${userInfo && userInfo.firstname ? userInfo.firstname : 'Guest'} ${userInfo && userInfo.lastname}` : ''}
                           disabled
                        /> : <Skeleton height={30} />}
                  </>
               }
            </div>
            :
            loginType == 0 && urlSearchParams ? 
            <>
               <div className="col-12 col-lg-3 col-md-6 col-xs-12">
               <h5 className="mb-3"> Login </h5>
               <label className="small-text">
                        {shippingTotal || cart.storeType !== 'Shopify' ? <><strong>Logged in as </strong> <span className="required">*</span></> : <Skeleton />}
                     </label>
                     {shippingTotal || cart.storeType !== 'Shopify' ?
                        <input
                           id="email"
                           name="email"
                           type="text"
                           placeholder="Email"
                           className="form-control"
                           autoComplete="off"
                           value={userInfo ? `${userInfo && userInfo.firstname ? userInfo.firstname : 'Guest'} ${userInfo && userInfo.lastname}` : ''}
                           disabled
                        /> : <Skeleton height={30} />}
               </div>
            </>
            :
            <></>
         }
            <div className="col-12 col-lg-3 col-md-6 col-xs-12">
               <Payment
                  animateCSS={animateCSS}
                  isShipDiffrentAddress={isShipDiffrentAddress}
                  handleChange={handleChange}
                  onCardSelectionChanged={(val) => { onCardSelectionChanged(val) }}
                  payWithNewCard={payWithNewCard}
                  setPayWithNewCard={(value) => {
                     if (!value) {
                        dispatch(addUsrAddress(JSON.parse(localStorage.getItem("loggedInUser"))?.address));
                     }
                     setPayWithNewCard(value);
                  }}
                  setCallBackCvv={(val) => setCvv(val)}
                  shippingTotal={shippingTotal}
                  loginType={loginType}
               />
            </div>
            <div className="col-12 col-lg-3 col-md-6 col-xs-12 small">
               <Shipping
                  shippingOptionSelect={shippingOptionSelect}
                  payWithNewCard={payWithNewCard}
                  setShippingOptionSelect={(data) => {
                     setShippingTotal(Number(data.addlInfo.price));
                     setShippingOptionSelect(data.addlInfo);
                  }}
                  isShipDiffrentAddress={isShipDiffrentAddress}
                  setIsShipDiffrentAddress={(data) => {
                     setIsShipDiffrentAddress(data);
                     if (!data) {
                        dispatch(addUserShipAddress({}));
                        if (cartProductsReducer.length > 0 && userAddress && userAddress.address) {
                           getShippingInfo(userAddress);
                        }
                     }
                  }}
                  handleChange={handleChange}
                  shippingTotal={shippingTotal}
                  loginType={loginType}
               />
            </div>
            <div className='mobile-view'>
               <Cart
                  cartCount={cartCount}
                  cartTotal={cartTotal}
                  taxTotal={taxTotal}
                  shippingTotal={shippingTotal}
                  shippingOptionSelect={shippingOptionSelect}
                  totalDiscount={totalDiscount}
                  submitCheckout={submitCheckout}
                  submitLoader={submitLoader}
               />
            </div>
         </div>
      </div>
   )
}

export default Home;
