import React, { useState } from 'react'
import Home from "./Home/Home";
import Receipt from "./Receipt";

function Dashboard() {
  const [type, setType] = useState(1);
  const [logo ,setLogo] = useState(null);
  const [receiptData, setReceiptData] = useState({});
  return (
    <div>
      {logo &&
      <nav class="navbar navbar-light container">
      <a class="navbar-brand" href="#">
        <img class="d-inline-block align-top logo-img" width="185" height="68" src={logo} alt="logo" />
      </a>
    </nav>
      }
      {type === 0 ? <Receipt data={receiptData} /> : <Home logo={logo} setLogo={setLogo} setType={setType} setReceiptData={setReceiptData} />}
    </div>
  )
}

export default Dashboard;
