export const getRandom = (length) => {
  return Math.floor(
    Math.pow(10, length - 1) + Math.random() * 9 * Math.pow(10, length - 1)
  )
}

export const animateCSS = (element, animation, prefix = 'animate__') =>
new Promise((resolve, reject) => {
  const animationName = `${prefix}${animation}`;
  const node = document.getElementById(element);
  node?.classList.add(`${prefix}animated`, animationName);
  function handleAnimationEnd(event) {
    event.stopPropagation();
    node?.classList.remove(`${prefix}animated`, animationName);
    resolve('Animation ended');
  }
  node?.addEventListener('animationend', handleAnimationEnd, {
    once: true,
  });
});

export const formatMoney = (number) => {
  return number.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export const userFullAddress = (address) => {
  let fullAddress = '';
  if (address && Object.keys(address).length !== 0) {
    if(address.addressLine1) {
      fullAddress += address.addressLine1;
    }
    if(address.addressLine2) {
      fullAddress += `, ${address.addressLine2}`;
    }
    if(address.cityName || address.city) {
      fullAddress += `, ${address.cityName ? address.cityName : address.city}`;
    }
    if(address.state) {
      fullAddress += `, ${address.state}`;
    }
    if(address.country && address.country.name) {
      fullAddress += `, ${address.country.name}`;
    } else {
      fullAddress += `, ${address.country ? address.country : address.countryName}`;
    }
    if(address.postalCode) {
      fullAddress += `, ${address.postalCode}`;
    }
  }
  return `${fullAddress}`
}