import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { withStateHandlers } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps";

const enhance = _.identity;
const MapWithAMakredInfoWindow = (
   withStateHandlers(
      () => ({
         isOpen: false,
      }),
      {
         onToggleOpen:
            ({ isOpen }) =>
               () => ({
                  isOpen: !isOpen,
               }),
         // onToggleOpen: "",
      },
      {
         onToggleOpen:
            ({ isOpen }) =>
               () => ({
                  isOpen: !isOpen,
               }),
         // onToggleOpen: "",
      }
   ),
   withScriptjs,
   withGoogleMap
)((props) => (
   <GoogleMap defaultZoom={8} defaultCenter={props.lat}>
      <Marker
         position={props.lat}
         onClick={props.onToggleOpen}
      >
         <InfoWindow>
            <div>
               <span className="text-mute">
                  Shipping address </span> <br />
               <span>{props.address}</span>
            </div>
         </InfoWindow>
      </Marker>
   </GoogleMap>
));

const ReactGoogleMaps = (props, index) => [
   <MapWithAMakredInfoWindow
      googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCs6mzbRW8QYOzNkPGWG9ghCYfOCT5vOIc&v=3.exp&libraries=geometry,drawing,places"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `275px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      lat={props.lat}
      address={props.address}
      key={index}
   />,
];

MapWithAMakredInfoWindow.propTypes = {
   lat: PropTypes.object,
   lang: PropTypes.any,
   info: PropTypes.any,
};

export default enhance(ReactGoogleMaps);
