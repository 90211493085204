import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Input from "react-phone-number-input/input";
import { 
  mobileLogin, 
  guestEmailLogin, 
  sendOTPCode, 
  verifyOTP, 
  resendOTPCode,
  getShipping,
  getDiscount,
} from "../../../services/authServices";
import { 
  addUsrDetails,
  addUsrAddress, 
  addUserCards, 
  addUserShipAddress, 
  addUserShipAddressList, 
  addPossibleAddress,
  addShippingOption
} from '../../../redux/actions/authActions';
import { headerOptions, API_URL_FOUR } from "../../../config/config";
import NumberFormat from "react-number-format";
import OtpTimer from "otp-timer";
import { getRandom } from "../../../helpers/utils";
import { Link } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';

function Auth(props) {
  const {  setTotalDiscount, handleChange } = props;
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const userInfo = useSelector((state) => state.auth.userInfo);
  const merchantUrlData = useSelector((state) => state.auth.merchantUrlData);
  const merchantData = useSelector((state) => state.auth.merchantData);
  const cartProductsReducer = useSelector((state) => state.auth.products);
  const cartItemId = useSelector((state) => state.auth.cart?.cartItemId);
  const [isGuestLogin, setIsGuestLogin] = useState(1);
  const [mobileNumberInput, setMobileNumberInput] = useState('');
  const [loginResponseState, setLoginResponseState] = useState({});
  const [otpCode, setOtpCode] = useState();
  const [isOtpSent, setisOtpSent] = useState(false);
  // RESEND OTP
  const resendOTP = (merchantAPIKey, loginResponse) => {
    resendOTPCode(merchantAPIKey, loginResponse.sessionToken)
      .then(function (response) {
        console.log("response", response);
      })
      .catch(function (error) {
          console.log("error", error);
      });
  }
  // VERIFY OTP
  const verifyOTPCode = (value, loginResponseData) => {
    let MobileNo = 0
    if (mobileNumberInput) {
        MobileNo = mobileNumberInput.substring(2)
    } else {
        MobileNo = 0
    }
    verifyOTP(value, loginResponseData.sessionToken)
      .then(function (response) {
        if(response.data && response.data.success) {
          if (cartProductsReducer.length > 0) {
            getDiscountAPI(cartProductsReducer, loginResponseData?.sessionToken);
          }
          localStorage.removeItem("sessionToken");
          localStorage.removeItem("mobileNo");
          localStorage.removeItem("userType");
          localStorage.setItem("sessionToken", loginResponseData?.sessionToken);
          localStorage.setItem("mobileNo", MobileNo);
          localStorage.setItem("userType", isGuestLogin);
          updateAuthReducer(loginResponseData);
        } else {
          addToast("Invalid verification code", {
            appearance: "error",
          });
        }
      })
      .catch(function (error) {
          console.log("error", error);
          addToast("Something went wronge", {
            appearance: "error",
          });
      });
  }
  // SEND OTP
  const sendOTP = (merchantAPIKey, loginResponse) => {
    sendOTPCode(merchantAPIKey, loginResponse.sessionToken)
      .then(function (response) {
        if(response.status === 200 && response.data.success) {
          setisOtpSent(true);
          setLoginResponseState(loginResponse);
          addToast("OTP sucessfully sended", {
            appearance: "success",
          })
        } else {
          addToast("Failure OTP send", {
            appearance: "error",
          });
        }
      })
      .catch(function (error) {
        addToast("Failure mobile number", {
          appearance: "error",
        });
      });
  };
  // LOGIN WITH GUEST USER
  const guestEmailLoginAPI = (MobileNo) => {
    let No = MobileNo;
    let FName = No.substring(0, 5);
    let LName = No.substring(5, 10);
    var regMobile = /^[0-9]+$/;
    if (!No || !regMobile.test(No)) {
      return false
    }
    let generatedEmail = No + `@kachyng.net`;
    let ProviderId = getRandom(16);
    let userParams = {
      email: generatedEmail,
      authProvider: "webstore",
      authProviderId: ProviderId,
      deviceVendor: "",
      deviceRegistrationId: "",
      firstname: FName,
      lastname: LName,
      deviceOsVersion: "",
      deviceName: "",
      deviceId: "",
      profileImageURL: "",
      mobileAppVersion: "",
    }      
    guestEmailLogin(headerOptions, userParams)
      .then(function (response) {
        if(response.data && response.data.success) {
          if (cartProductsReducer.length > 0) {
            getDiscountAPI(cartProductsReducer, response.data.sessionToken);
          }
          localStorage.removeItem("sessionToken");
          localStorage.removeItem("mobileNo");
          localStorage.removeItem("userType");
          localStorage.setItem("sessionToken", response.data.sessionToken);
          localStorage.setItem("mobileNo", MobileNo);
          localStorage.setItem("userType", isGuestLogin);
          updateAuthReducer(response.data);          
        } else {
          addToast("Failure guest login", {
            appearance: "error",
          });
        }
      })
      .catch(function (error) {
        addToast("Something went wrong, please try again", {
          appearance: "error",
        });
      });
  }
  // UPDATE AUTH REDUCER
  const updateAuthReducer = (responseData) => {
    let userInfo = {
      "userId": responseData?.user?.userId,
      "email": responseData?.user?.email,
      "name": responseData?.user?.name,
      "firstname": responseData?.user?.firstname,
      "lastname": responseData?.user?.lastname,
      "phoneNumber": responseData?.user?.phoneNumber,
      "isPhoneVerified": responseData?.user?.isPhoneVerified,
      "hasAddress": responseData?.user?.hasAddress,
      "pendingTransactions": responseData?.user?.pendingTransactions,
      "pendingAuthentications": responseData?.user?.pendingAuthentications,
      "url": responseData?.user?.url,
      "imageUrl": responseData?.user?.imageUrl
    }
    localStorage.setItem(
      "loggedInUserData",
      JSON.stringify(userInfo)
   );
    dispatch(addUsrDetails(userInfo));
    dispatch(addUsrAddress(responseData.user.address));
    // localStorage.setItem(
    //   "loggedInUserSavedCard",
    //   JSON.stringify(responseData.user.fundExternals)
    // );
    localStorage.setItem(
      "loggedInUser",
      JSON.stringify(responseData.user)
    );
    dispatch(addUserCards(responseData.user.fundExternals));
    dispatch(addUserShipAddress(responseData.user.shippingAddress));
    dispatch(addUserShipAddressList(responseData.user.shippingAddressList));
    dispatch(addPossibleAddress(responseData?.user?.possibleAddress));
    if (cartProductsReducer.length > 0) {
      if (responseData.user && 
        responseData.user.shippingAddress && 
        responseData.user.shippingAddress.addressLine1 && 
        responseData.user.shippingAddress.cityName && 
        responseData.user.shippingAddress.state && 
        responseData.user.shippingAddress.postalCode) {
        getShippingInfo(userInfo, responseData.user.shippingAddress, cartProductsReducer);
      } else {
        console.log(responseData.user.address);
        if (responseData.user && 
          responseData.user.address && 
          responseData.user.address.addressLine1 && 
          responseData.user.address.cityName) {
          getShippingInfo(userInfo, responseData.user.address, cartProductsReducer);
        }
      }
    }
  }
  // LOOGIN USER WITH MOBILE NUMBER
  const inputPhonenumber = async (value, loginType) => {
    setMobileNumberInput(value);
    let MobileNo = 0
    if (value) {
      MobileNo = value.substring(2)
    }
    if(Number(loginType) === 1) {
      if (MobileNo.length === 12 || MobileNo.length === 10) {
        if (cartProductsReducer.length > 0) {
          let params = {
            mobile: MobileNo,
            countryCode: "+91",
            merchantAPI: merchantData.merchantKey,
            validateMobile: "Y", //"Y",N
            sendToken: "Y", //"Y",N
          }
          mobileLogin(headerOptions, params)
            .then(function (response) {
              console.log(response.data);
              if (response.data.success) {
                sendOTP(merchantData.merchantKey, response.data);
              } else {
                addToast("Failure mobile number", {
                  appearance: "error",
                });
              }
            })
            .catch(function (error) {
              addToast("Something went wrong, please try again", {
                appearance: "error",
              });
            });
        } else {
          addToast("Cart is empty", {
            appearance: "error",
          });
        }
      }
    } else {
      if (MobileNo.length === 12 || MobileNo.length === 10) {
        guestEmailLoginAPI(MobileNo);
      }
    }
  }
  // GET SHIPPING API's
  const getShippingInfo = (userInfo, address, products) => {
    getShipping(
      {
        "cartItemId": cartItemId,
        "email": userInfo.email ? userInfo.email : '',
				"ShippingAddress": {
					"first_name": `${userInfo?.firstname}`,
					"last_name": `${userInfo.lastname ? userInfo.lastname : 'Demo'}`,
					"address1": `${address?.addressLine1}`,
					"city": `${address?.cityName}`,
          "province_code": address.region && address.region.name ? `${address?.region.name}` : `${address?.country}`,
          "country_code": address.country && address.country.countryCode ? `${address?.country.countryCode}` : `${address?.country}`,
					"phone": `${userInfo.phoneNumber}`,
					"zip": `${address?.postalCode}`
				}
			},
      {
        'apiKey': merchantUrlData && merchantUrlData.merchant && merchantUrlData.merchant.store && merchantUrlData.merchant.store.apiKey ? `${merchantUrlData?.merchant?.store?.apiKey}` : API_URL_FOUR.apiKey,
        'apiSecret': merchantUrlData && merchantUrlData.merchant && merchantUrlData.merchant.store && merchantUrlData.merchant.store.apiKey ? `${merchantUrlData?.merchant?.store?.secretKey}` : API_URL_FOUR.apiSecret,
     }
    )
      .then(function (response) {
        if(response.status && response.data) {
          dispatch(addShippingOption(response.data.shipping_rates));
        }
      })
      .catch(function (error) {
          console.log("error", error);
      });
  }
  // GET DISCOUNT API's
  const getDiscountAPI = (products, sessionToken) => {
    const productArr = [];
    if(products.length > 0) {
      products.forEach(element => {
        let proObj = {};
        proObj.productId = element.id;
        proObj.quantity = element.quantity;
        productArr.push(proObj);      
      });
    }
    const params = {
      "products": productArr
    }
    
    getDiscount(params, sessionToken)
      .then(function (response) {
        if(response.data && response.data.success) {
          if (Number(response.data.discounts.user.actualDiscount) > 0) {
            setTotalDiscount(Number(response.data.discounts.user.actualDiscount));
          }
        }
      })
      .catch(function (error) {
        console.log("error", error);
      });
  }

  return (
    <>
      
        {!localStorage.getItem("sessionToken") && (
          <>
            <h5 className="mb-3"> Login </h5>
            <div className="mb-3">
              <div>
                <label>
                  <input
                    id="guest"
                    type="radio"
                    name="login-method"
                    value="0"
                    checked={Number(isGuestLogin) === 0 ? 'checked' : ''}
                    onClick={() => {
                      setisOtpSent(false);
                      setMobileNumberInput('');
                      setIsGuestLogin(0);
                      addToast("Guest User Mode", {
                        appearance: "success",
                      });
                    }}
                  />
                  &nbsp; Checkout as guest
                </label>
              </div>
              <div className="mt-2">
                <label>
                  <input
                    id="Register"
                    type="radio"
                    name="login-method"
                    value="1"
                    checked={Number(isGuestLogin) === 1 ? 'checked' : ''}
                    onClick={() => {
                      setisOtpSent(false);
                      setMobileNumberInput('');
                      setIsGuestLogin(1);
                      addToast("Register User Mode", {
                        appearance: "success",
                      });
                    }}
                  />
                  &nbsp; Have account? Login.
                </label>
              </div>
            </div>
            {Number(isGuestLogin) === 1 ? (
              <><label className="small-text pb-1"><strong>Login with mobile number</strong></label><br /></>
            ) : (
              <><label className='small-text pb-1'><strong>Mobile</strong> - used only to send SMS receipt{" "}</label><br /></>
            )}
            <div className="login-mobile-input">
              <Input
                country='US'
                value={mobileNumberInput}
                maxLength='14'
                placeholder='Enter mobile #'
                className='form-control text-box'
                onChange={(number) => { 
                  inputPhonenumber(number, isGuestLogin);
                  handleChange('userInfo', 'mobile', number);
                }}
                id='mobile'
              />
              {isOtpSent && (
                <i class="fas fa-edit" onClick={() => { setisOtpSent(false); localStorage.clear() }}></i>
              )}
            </div>
            {Number(isGuestLogin) === 1 && (
              <>
                {isOtpSent ? (
                  <div className="form-group mt-2">
                    <label className="small-text px-3 pb-1">SMS code * </label> <br />
                    <NumberFormat
                      id="OTP"
                      className='form-control text-box'
                      format='######'
                      autoComplete='off'
                      allowEmptyFormatting
                      placeholder='SMS verification code '
                      mask=''
                      // disabled={disabled}
                      onChange={(e)=> {
                        setOtpCode(e.target.value);
                      }}
                      onValueChange={(verificationCode) => {
                        if (verificationCode.value.length > 5) {
                          verifyOTPCode(verificationCode.value, loginResponseState);
                        }
                      }}
                      value={otpCode}
                    />
                    <label className="small-text message px-3 pt-2">Enter valid OTP</label>
                    {/* <button type="button" className="btn mt-4" onClick={() => {}}>
                      <span>Resend OTP</span>
                    </button> */}
                    <div className='OtpTimerWrap'>
                      <OtpTimer
                        className="btn mt-4"
                        seconds={10}
                        minutes={0}
                        resend={() => { resendOTP(merchantData.merchantKey, loginResponseState) }}
                        ButtonText='Resend OTP'
                        text='Did not get code?'
                        buttonColor='#fff'
                        background='#0033cc'
                      />
                    </div>
                  </div>
                ) : (
                  <>
                    <p className="text-center mt-sm mt-2 mb-2"> Or </p>
                    <div className="form-group">
                      <button type="button" className="btn-custom btn-facebook">
                        {' '}
                        Login with Facebook{' '}
                      </button>
                      <button type="button" className="btn-custom btn-apple">
                        {' '}
                        Login with Apple{' '}
                      </button>
                      <button type="button" className="btn-custom btn-gmail">
                        {' '}
                        Login with Google{' '}
                      </button>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        )}
        {localStorage.getItem("sessionToken") && (
          <>
            <h5>{merchantData?.merchantName}</h5>
            <div className="form-group">
              <label className="small-text mb-2">
                Logged in as
                <Link
                    to={`${merchantUrlData?.decodeMerchant?.logoutUrl ? merchantUrlData?.decodeMerchant?.logoutUrl : '/'}`}
                    onClick={(event) => {
                      event.preventDefault();
                      localStorage.clear();
                      window.location.href = merchantUrlData?.decodeMerchant?.logoutUrl ? merchantUrlData?.decodeMerchant?.logoutUrl : '/';
                    }}
                  >
                    {" "}
                    <span className='alignRight'>
                      {" "}
                      Logout{" "}
                    </span>{" "}
                </Link>
              </label> <br />
              <input type="text" readOnly className="form-control text-box" value={userInfo?.name} />
            </div>
          </>
        )}
     
    </>
  );
}

export default Auth;
