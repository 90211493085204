
module.exports.API_URL_LOCAL = {
    url: "http://localhost:3000/api",
}
module.exports.API_URL_THREE = {
    url: "https://frosty-tesla.45-32-253-204.plesk.page/index.php/shopify/v2",
    apiKey: '0LURWPM9M55TLNh4YeJOSlr9J0qw44DuCUwe4cosFznVlk0XUOHHzhY8dcvy',
    apiSecret: '$2y$10$egpQ3LAMYEkimPIaqtDacOY5ygoh6/gsyy6DOgy6TamrLIGtx3iOW',
}
module.exports.API_URL = {
    url: "https://jbk70akn8j.execute-api.us-east-1.amazonaws.com/dev",
    authorization: "Basic YXBpS2V5OldRSjFXM1EyOXdCM1IwYUZBWWZMUW44NUxhRXozZmxi",
    apikey: "WQJ1W3Q29wB3R0aFAYfLQn85LaEz3flb",
}
module.exports.API_URL_TWO = {
    url: "https://8b2nsnl1tj.execute-api.us-east-1.amazonaws.com/dev",
}
module.exports.headerOptions = {
    // "Access-Control-Allow-Origin": "https://jbk70akn8j.execute-api.us-east-1.amazonaws.com/api/",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "GET,POST",
    "Access-Control-Allow-Credentials": false,
}
module.exports.API_URL_FOUR = {
    url: "https://payment.kachyng.com/private-app/index.php/shopify/v2",
    apiKey: 'aM7wBIvStaNfsrCeD8uXllZHgWyIMalqx9JFjVbf1BQuQMxEJbrbsZwkzZQl',
    apiSecret: '$2y$10$Ot9adIkRVwH4J4FAEphFou21gnwtefX7bUPqYjw0yMZU78i47Zpb2',
}