import axios from 'axios';
import { API_URL_LOCAL, API_URL, API_URL_TWO, API_URL_FOUR } from "../config/config";

export const getAPIResponse = (url) => {
   return axios
      .get(
         `${url}`
      )
      .then((Response) => {
         return Response.data;
      })
      .catch((error) => {
         throw error;
      })
};

export const getProduct = () => {
   return axios
      .get(
         `${API_URL_LOCAL.url}/cartData.json`
      )
      .then((Response) => {
         return Response.data;
      })
      .catch((error) => {
         throw error;
      })
};

export const getMerchant = (merchantId) => {
   return axios
      .get(`${API_URL_TWO.url}/merchant/${merchantId}`)
      .then((response) => {
         return response;
      })
      .catch((error) => {
         throw error;
      })
};

export const getCheckoutData = (cartId, merchantAPIKey) => {
   return axios
      .get(`${API_URL.url}/store/${cartId}`,{
      auth: {
         username: `apiKey`,
         password: `WQJ1W3Q29wB3R0aFAYfLQn85LaEz3flb`,
      },
   }
      )
      .then((response) => {
         return response;
      })
      .catch((error) => {
         throw error;
      })
};

export const mobileLogin = (headerOptions, params) => {
   return axios
      .post(
         `${API_URL.url}/auth/mobile`,
         {
            headerOptions,
            user: params,
         },
         {
            timeout: 2000,
         }
      )
      .then((response) => {
         return response;
      })
      .catch((error) => {
         throw error;
      })
};

export const sendOTPCode = (merchantAPIKey, sessionToken) => {
   return axios
      .post(
         `${API_URL.url}/mobile/send-code`,
         {
            merchantAPI: merchantAPIKey,
         },
         {
            auth: {
               username: `sessionToken`,
               password: `${sessionToken}`,
            },
         }
      )
      .then((response) => {
         return response;
      })
      .catch((error) => {
         throw error;
      })

};

export const verifyOTP = (verificationCode, sessionToken) => {
   return axios
      .post(
         `${API_URL.url}/mobile/verify-code`,
         {
            approveToken: verificationCode,
         },
         {
            auth: {
               username: `sessionToken`,
               password: `${sessionToken}`,
            },
         }
      )
      .then((response) => {
         return response;
      })
      .catch((error) => {
         throw error;
      })
};

export const resendOTPCode = (merchantAPIKey, sessionToken) => {
   return axios
      .post(
         `${API_URL.url}/mobile/send-code`,
         {
            merchantAPI: merchantAPIKey,
         },
         {
            auth: {
               username: `sessionToken`,
               password: `${sessionToken}`,
            },
         }
      )
      .then((response) => {
         return response;
      })
      .catch((error) => {
         throw error;
      })
};

export const guestEmailLogin = (headerOptions, params) => {
   return axios
      .post(
         `${API_URL.url}/auth/email`,
         {
            headerOptions,
            user: params,
         },
      )
      .then((response) => {
         return response;
      })
      .catch((error) => {
         throw error;
      })
};

export const getShipping = (params, headers) => {
   return axios
      .post(
         `${API_URL_FOUR.url}/shipping_rates`, params,
         {
            headers: {
               'Api-Key': headers.apiKey,
               'Api-Secret': headers.apiSecret,
            }
         }
      )
      .then((response) => {
         return response;
      })
      .catch((error) => {
         throw error;
      })
};

export const getDiscount = (params, sessionToken) => {
   return axios
      .post(
         `${API_URL.url}/discount`, params,
         {
            auth: {
               username: `sessionToken`,
               password: `${sessionToken}`,
            },
         }
      )
      .then((response) => {
         return response;
      })
      .catch((error) => {
         throw error;
      })
};

export const getOrderDetail = (
   params,
   merchantData,
   decodeMerchant,
   sessionToken,
   cartItem = null,
   selectedShippingOptions = null,
   cartItemId,
) => {
   return axios
      .post(
         `${merchantData.merchantCallBackUrl}`,
         {
            "cartItemId": cartItemId,
            "shippingLines": [
               {
                  "id": selectedShippingOptions.data && selectedShippingOptions.data.id,
                  "code": selectedShippingOptions.data && selectedShippingOptions.data.title ? selectedShippingOptions.data.title : '',
                  "price": selectedShippingOptions.data && selectedShippingOptions.data.price ? selectedShippingOptions.data.price : '',
                  "title": selectedShippingOptions.data && selectedShippingOptions.data.title ? selectedShippingOptions.data.title : '',
               }
            ],
            "kachyngData": [
               {
                  "type": "order",
                  "order": {
                     "href": `${API_URL.url}/order/${params}`
                  }
               }
            ]
         },
         {
            headers: {
               'Api-Key': `${decodeMerchant?.store?.apiKey}`,
               'Api-Secret': `${decodeMerchant?.store?.secretKey}`,
            }
         }
      )
      .then((response) => {
         return response;
      })
      .catch((error) => {
         throw error;
      })
};