import {
   ADD_USER_DETAILS,
   ADD_USER_ADDRESS,
   ADD_PRODUCT,
   ADD_MERCHANT_URL,
   ADD_MERCHANT,
   ADD_USER_CARDS,
   ADD_USER_SHIP_ADDRESS,
   USER_SHIP_ADDRESS_LIST,
   USER_POSSIBLE_ADDRESS,
   SHIPPING_OPTIONS_LOADER,
   SHIPPING_OPTIONS,
   CHECKOUT_SUBMIT,
   UPDATE_ORDER_DETAIL,
   ADD_CART,
   SELECTED_SHIPPING_OPTIONS,
   CHECKOUT_DATA,
} from "../actions/authActions";

const initState = {
   userInfo: {},
   userAddress: {},
   userSaveCard: [],
   userPossibleAddress: {},
   setllarBalance: {},
   userShippingAddress: {},
   userShippingAddressList: [],
   products: [],
   emptyCart: '',
   merchantUrlData: {},
   merchantData: {},
   shippingOptions: {
      loader: false,
      data: []
   },
   orderDetail: {},
   cart: {},
   shippingOptionsLoader: false,
   checkoutData: null
};

const authReducer = (state = initState, action) => {
   const tempState = { ...state };
   switch (action.type) {
      case ADD_USER_DETAILS: {
         return {
            ...state,
            userInfo: action.payload,
         };
      }
      case ADD_USER_ADDRESS: {
         return {
            ...state,
            userAddress: action.payload,
         };
      }
      case ADD_PRODUCT: {
         return {
            ...state,
            products: action.payload,
            emptyCart: action.payload.length > 0 ? false : true,
         };
      }
      case ADD_CART: {
         return {
            ...state,
            cart: action.payload,
         };
      }
      case ADD_MERCHANT_URL: {
         return {
            ...state,
            merchantUrlData: action.payload,
         };
      }
      case ADD_MERCHANT: {
         return {
            ...state,
            merchantData: action.payload,
         };
      }
      case ADD_USER_CARDS: {
         return {
            ...state,
            userSaveCard: action.payload,
         };
      }
      case ADD_USER_SHIP_ADDRESS: {
         return {
            ...state,
            userShippingAddress: action.payload,
         };
      }
      case USER_SHIP_ADDRESS_LIST: {
         return {
            ...state,
            userShippingAddressList: action.payload,
         };
      }
      case USER_POSSIBLE_ADDRESS: {
         return {
            ...state,
            userPossibleAddress: action.payload,
         };
      }
      // case SHIPPING_OPTIONS_LOADER: {
      //    return {
      //       ...state,
      //       shippingOptionsLoader: { data: [], loader: true },
      //    };
      // }
      case SHIPPING_OPTIONS: {
         return {
            ...state,
            shippingOptions: { data: action.payload, loader: false },
            shippingOptionsLoader: false
         };
      }

      case 'SHIPPING_BILLING_OPTIONS': {
         return {
            ...state,
            shippingBillingOptions: true,
            // shippingOptions: { data: [], loader: true }
         };
      }

      case 'SHIPPING_OTHER_OPTIONS': {
         return {
            ...state,
            shippingBillingOptions: false,
            shippingOptionsLoader: true
            // shippingOptions: { data: [], loader: true }
         };
      }

      case 'CHECKOUT_DATA': {
         return {
            ...state,
            checkoutData: action.data
         };
      }

      case SELECTED_SHIPPING_OPTIONS: {
         return {
            ...state,
            selectedShippingOptions: { data: action.payload, loader: false },
         };
      }

      case CHECKOUT_SUBMIT: {
         tempState[action.payload[0]] = { ...tempState[action.payload[0]], ...action.payload[1] }
         return tempState;
      }

      case "RESET": {
         return initState
      }

      case UPDATE_ORDER_DETAIL: {
         return {
            ...state,
            orderDetail: action.payload,
         };
      }
      default:
         return state;
   }
};

export default authReducer;
