import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
// import GooglePayButton from "@google-pay/button-react";
import { formatMoney } from '../../../helpers/utils';

function Cart(props) {
   const { shippingTotal, totalDiscount, submitCheckout, cartCount, cartTotal, taxTotal, submitLoader, loginType } = props;
   const cartProductsReducer = useSelector((state) => state.auth.products);
   const cart = useSelector((state) => state.auth.cart);

   const pPrice = (price) => {
      if (Number(price) > 0) {
         return Number(price);
      } else {
         return 0.00;
      }
   }

   const pPriceAfterDiscount = (price, discount = 0) => {
      if (Number(price) > 0) {
         return (Number(price) - Number(discount))
      } else {
         return 0.00;
      }
   }

   // const paymentRequest = {
   //    apiVersion: 2,
   //    apiVersionMinor: 0,
   //    allowedPaymentMethods: [
   //       {
   //          type: "CARD",
   //          parameters: {
   //             allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
   //             allowedCardNetworks: ["MASTERCARD", "VISA"]
   //          },
   //          tokenizationSpecification: {
   //             type: "PAYMENT_GATEWAY",
   //             parameters: {
   //                gateway: "example"
   //             }
   //          }
   //       }
   //    ],
   //    merchantInfo: {
   //       merchantId: "12345678901234567890",
   //       merchantName: "Demo Merchant"
   //    },
   //    // transactionInfo: {
   //    //   totalPriceStatus: "FINAL",
   //    //   totalPriceLabel: "Total",
   //    //   totalPrice: "100.00",
   //    //   currencyCode: "USD",
   //    //   countryCode: "US"
   //    // },
   //    transactionInfo: {
   //       totalPriceStatus: "FINAL",
   //       totalPriceLabel: "Total",
   //       totalPrice: `${cartTotal + shippingTotal + taxTotal - totalDiscount}`,
   //       currencyCode: "USD",
   //       countryCode: "US"
   //    }
   // };

   return (
      <>
         <h5 className='cart-title'> Cart </h5>
         <div className="cart-section">
            <div className="col-md-12">
               <hr className="line-separator" />
               <div className="col-md-12">
                  <div className="Collapsible">
                     <div className="cart-container">
                        <a
                           data-toggle="collapse"
                           href="#collapseExample"
                           role="button"
                           aria-expanded="false"
                           aria-controls="collapseExample"
                           className="collapsed"
                        >
                           <div className="cart-icon-2">
                              <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-alt-circle-up" className="svg-inline--fa fa-arrow-alt-circle-up fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M8 256C8 119 119 8 256 8s248 111 248 248-111 248-248 248S8 393 8 256zm292 116V256h70.9c10.7 0 16.1-13 8.5-20.5L264.5 121.2c-4.7-4.7-12.2-4.7-16.9 0l-115 114.3c-7.6 7.6-2.2 20.5 8.5 20.5H212v116c0 6.6 5.4 12 12 12h64c6.6 0 12-5.4 12-12z"></path></svg>
                           </div>
                           {loginType === 'Guest' || shippingTotal || cart.storeType !== 'Shopify' ? <div className="cart-icon">
                              <span className="cart-count">{cartCount}</span>
                           </div> : <Skeleton className='cart-icon-skeleton' circle />}
                        </a>
                     </div>
                     <div className="collapse" id="collapseExample">
                        {cartProductsReducer && cartProductsReducer.length > 0 ? (
                           <div className="row">
                              <div className="col-12">
                                 <div className="table-content table-responsive cart-table-content-custom">
                                    <div className="scrollbar-container ps">
                                       <div className="content">
                                          <table>
                                             <tbody>
                                                {cartProductsReducer.map((data, i) => (
                                                   <tr key={i}>
                                                      <td className="product-thumbnail cursor-pointer">
                                                         {/* <Link to={"/"}> */}
                                                         <img className="img-fluid" src={data?.image?.[0]} alt="" />
                                                         {/* </Link> */}
                                                      </td>
                                                      <td className="product-name product-price-cart product-quantity product-subtotal">
                                                         <div className="product-detail">
                                                            <p className="line-height-md cursor-pointer">
                                                               {data.name}
                                                            </p>
                                                            <p>
                                                               <span className="amount old">{formatMoney(pPrice(data.price))}</span>
                                                               <span className="amount">{formatMoney(pPriceAfterDiscount(data.price, data.discount))}</span>
                                                            </p>
                                                            <div className="cart-plus-minus">
                                                               <div className="col-3">Qty</div>
                                                               <div className="col-4">{data.quantity}</div>
                                                               <span className="col-5 text-right">{formatMoney(pPriceAfterDiscount(data.price, data.discount))}</span>
                                                               {/* <button type="button" className="dec qtybutton">-</button> */}
                                                               {/* <input className="cart-plus-minus-box" type="text" readOnly value={data.quantity} /> */}
                                                               {/* <button type="button" className="inc qtybutton">+</button> */}
                                                            </div>
                                                            {/* <span className="pull-right">{formatMoney(pPriceAfterDiscount(data.price, data.discount))}</span> */}
                                                            {/* <button type="button" className="close-icon">
                                                      <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                                        <path fill="none" stroke="#000" strokeWidth="2" d="M7,7 L17,17 M7,17 L17,7"></path>
                                                      </svg>
                                                    </button> */}
                                                         </div>
                                                      </td>
                                                   </tr>
                                                ))}
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        ) : (
                           <div className="no-cart">
                              <img alt="" src="/assets/img/cart.jpg" />
                              <div className="item-empty-area__text">No items found in cart</div>
                           </div>
                        )}
                     </div>
                  </div>
               </div>
               {cart.storeType === 'Shopify' && <div className="row form-group mb-2 mt-1">
                  {loginType === 'Guest' || shippingTotal || cart.storeType !== 'Shopify' ? 
                     <> <div className="col-5">Shipping</div>
                        <div className="col-7 text-right shipping-detail"> {formatMoney(shippingTotal)} </div>
                     </> :
                     <Skeleton />}
               </div>}
               <div className="row form-group mb-2">
                  {loginType === 'Guest' || shippingTotal || cart.storeType !== 'Shopify' ?
                     <> <div className="col-5">Discount</div>
                        <div className="col-7 text-right tax-rate">{formatMoney(totalDiscount)}</div>
                     </> :
                     <Skeleton />}
               </div>
               <div className="row form-group mb-2">
                  {loginType === 'Guest' || shippingTotal || cart.storeType !== 'Shopify' ?
                     <><div className="col-5">Tax</div>
                        <div className="col-7 text-right tax-rate">{formatMoney(taxTotal)}</div>
                     </> :
                     <Skeleton />}
               </div>
               <div className="row form-group mb-2">
                  {loginType === 'Guest' || shippingTotal || cart.storeType !== 'Shopify' ?
                     <> <div className="col-5"><strong> Total </strong></div>
                        <div className="col-7 text-right ">
                           <span className="total-rate">
                              <strong>{formatMoney(cartTotal)}</strong>
                           </span>
                        </div>
                     </> :
                     <Skeleton height={20} />}
               </div>
               <div className='col-12'>
                  <hr className="px-2" />
               </div>
            </div>
         </div>
         <div className='ii'>
            <h5 className="mb-2 coupon-title">Coupon </h5>
            <div className="form-group mb-3">
               {!loginType || loginType === 'Guest' || shippingTotal || cart.storeType !== 'Shopify' ? <input type="tel" className="form-control" placeholder="Enter coupon code" name="coupon" /> : <Skeleton height={30} />}
            </div>
            <div className="form-group">
               {!loginType || loginType === 'Guest' || shippingTotal || cart.storeType !== 'Shopify'?
                  <button
                     type="submit"
                     className={`btn-custom btn-Primary pay-button btn-font-20 ${submitLoader ? 'active' : ''} ${cartProductsReducer.length === 0 ? 'disabled' : ''}
            `}
                     color="olive"
                     id="submit-button"
                     fluid="true"
                     disabled={cartProductsReducer.length === 0 ? true : false}
                     onClick={submitCheckout}
                  >
                     Pay
                     {!submitLoader ? (
                  cart.storeType === 'Shopify' ? <>&nbsp;{formatMoney((cartTotal + shippingTotal + taxTotal - totalDiscount))}</>
                       : <>&nbsp;{formatMoney((cartTotal + taxTotal - totalDiscount))}</>
                     ) : (
                        <div>
                           {" "}
                           <div
                              className='snippet'
                              data-title='.dot-pulse'>
                              <div className='stage'>
                                 Processing
                                 &nbsp;{" "}
                                 <div className='dot-pulse'></div>
                              </div>
                           </div>{" "}
                        </div>
                     )}
                  </button>
                  :
                  <Skeleton height={60} baseColor='#3c78d8' />}
               {/* <GooglePayButton
                  environment="TEST"
                  //  buttonColor={buttonColor}
                  buttonType='plain'
                  buttonSizeMode='fill'
                  paymentRequest={paymentRequest}
                  onLoadPaymentData={paymentRequest => {
                     console.log("load payment data", paymentRequest);
                  }}
                  style={{ width: 263, height: 40 }}
               /> */}
               <p className="text-center">Your data is encrypted and secured </p>
               <hr />
            </div>
         </div>
      </>
   );
}

export default Cart;
