import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Map from '../components/map';
import { useSelector, useDispatch } from 'react-redux';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import {
   getOrderDetail
} from "../services/authServices";
import { setOrderDetail } from '../redux/actions/authActions';
import { useToasts } from 'react-toast-notifications';
import { userFullAddress } from '../helpers/utils'
import '../components/Home/style.scss';
function Receipt(props) {
   const { data } = props;
   const dispatch = useDispatch();
   const { addToast } = useToasts();
   const userAddress = useSelector((state) => state.auth.userAddress);
   const userShippingAddress = useSelector((state) => state.auth.userShippingAddress);
   const merchantUrlData = useSelector((state) => state.auth.merchantUrlData);
   const merchantData = useSelector((state) => state.auth.merchantData);
   const cartProductsReducer = useSelector((state) => state.auth.products);
   const cartItemId = useSelector((state) => state.auth.cart?.cartItemId);
   const shippingOptions = useSelector((state) => state.auth.shippingOptions);
   const selectedShippingOptions = useSelector((state) => state.auth.selectedShippingOptions);
   // const orderDetail = useSelector((state) => state.auth.orderDetail);
   const urlSearchParams = new URLSearchParams(useLocation().search);
   // const urlTransactionID = urlSearchParams.get('transactionID');
   // const urlShipping = urlSearchParams.get('shipping');
   // const urlTax = urlSearchParams.get('tax');
   // const urlTotal = urlSearchParams.get('total');
   const [urlOrderID, setUrlOrderID] = useState('');
   const [urlOrderNo, setUrlOrderNo] = useState('');
   const [urlShipping, setUrlShipping] = useState('');
   const [urlTax, setUrlTax] = useState('');
   const [urlTotal, setUrlTotal] = useState('');
   useEffect(() => {
      setUrlOrderID(data.urlOrderID);
      setUrlOrderNo(data.urlOrderNo);
      setUrlShipping(data.shipping);
      setUrlTax(data.tax);
      setUrlTotal(data.total);
   }, [data]);
   const [addressLatLong, setAddressLatLong] = useState({});

   useEffect(() => {
      if (urlOrderID) {
         if (merchantData.storeIntegrationValue === "Shopify") {
            getOrderDetail(
               urlOrderID,
               merchantData,
               merchantUrlData?.decodeMerchant,
               localStorage.getItem("sessionToken"),
               cartProductsReducer,
               selectedShippingOptions,
               cartItemId,
            )
               .then(function (response) {
                  if (response.data && response.data.success) {
                     dispatch(setOrderDetail(response.data));
                     if (response.data.redirect_url) {
                        window.location.href = response.data.redirect_url;
                     }
                  } else {
                     addToast(response.data.error, {
                        appearance: "error",
                     })
                  }
               })
               .catch(function (error) {
                  console.log("error", error);
               });
         }
      }
      // GET ADDRESS LAT LONG
      geocodeByAddress(`${userFullAddress(userAddress)}`)
         .then(results => getLatLng(results[0]))
         .then(({ lat, lng }) => {
            setAddressLatLong([{ "lat": lat }, { "lng": lng }]);
         }
         );
   }, [addToast, dispatch, merchantData, urlOrderID, userAddress]);

   return (
      <>
         <div class="breadcrumb-area pt-35 pb-35 bg-gray-3">
            <div class="container">
               <div class="breadcrumb-content text-center">
                  <span></span>
               </div>
            </div>
         </div>
         <div className="checkout-area pt-95 pb-100 your-order-area">
            <div className="container">
               <div className="row">
                  <div className="col-lg-6">
                     <div className="user-detail">
                        {/* <h2> {purchaseDetail.transaction?.merchantName}  </h2> */}
                        <p>Order Number: {urlOrderNo}</p>
                        {/* <p className='head-tag'> Thank you {purchaseDetail.transaction.fundExternal?.nickName}! </p> */}
                     </div>
                     <div className="row form-group">
                        <div className="col-lg-12">
                           <div className="inner-box">
                              {(userShippingAddress && userShippingAddress.address) || (userAddress && userAddress.address) ? (
                                 <Map
                                    address={
                                       `${userShippingAddress && userShippingAddress.address ? userShippingAddress.address : userAddress.address}`
                                    }
                                    lat={
                                       {
                                          lat: userShippingAddress && userShippingAddress.lat ? userShippingAddress.lat : userAddress.lat,
                                          lng: userShippingAddress && userShippingAddress.lng ? userShippingAddress.lng : userAddress.lng
                                       }
                                    }
                                 />
                              ) : (
                                 <>
                                    {addressLatLong && addressLatLong.length > 0 && (
                                       <Map
                                          address={
                                             `${userFullAddress(userAddress)}`
                                          }
                                          lat={
                                             {
                                                lat: addressLatLong?.[0]?.lat,
                                                lng: addressLatLong?.[1]?.lng
                                             }
                                          }
                                       />
                                    )}
                                 </>
                              )}
                              <div className="order-section">
                                 <p className="head-tag-2">Your order is confirmed!</p>
                                 <p>You will receive an email when your order is ready</p>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div className="row form-group">
                        <div className="col-lg-12">
                           <div className="inner-box address">
                              {/* <h4> Customer information section </h4> */}
                              {/* <p> {purchaseDetail.email} </p> <br /> */}
                              <h4>Shipping address </h4>
                              {/* <address>
                    {addressDetails?.address} */}
                              {/* <p>{purchaseDetail.addressDetail.address}</p> */}
                              {/* </address> */}
                              <p>
                                 {`${userShippingAddress && userShippingAddress.address && userShippingAddress.address ? userShippingAddress.address : userAddress.address ? userAddress.address : userFullAddress(userAddress)}`}
                              </p>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-lg-6 product-section">
                     <div className="inner-section-product">
                        <table width="100%">
                           <thead></thead>
                           <tbody>
                              {/* {cartItems.map((product, index) => {
                       return (
                          <tr key={index}>
                             <td width='20%'>
                                <img
                                   width='75'
                                   src={product?.image[0]}
                                   alt=''
                                />
                             </td>
                             <td width='50%'>{product.name}</td>
                             <td width='30%' align='right'>
                                {currency.currencySymbol +
                                   parseFloat(
                                      product.price
                                   ).toFixed(2)}
                             </td>
                          </tr>
                       )
                    })} */}
                           </tbody>
                        </table>
                        <hr className="separator" />
                        <table width="100%">
                           <thead></thead>
                           <tbody>
                              <tr className="top1">
                                 <td width="20">Subtotal</td>
                                 <td width="40" align="right">
                                    {/* {currency.currencySymbol +
                             parseFloat(
                                details.subTotal
                             ).toFixed(2)} */}
                                 </td>
                                 <td width="40" align="right">${urlTotal}</td>
                              </tr>
                              <tr className="top1">
                                 <td width="20"> Shipping</td>
                                 <td width="40" align="right">
                                    {/* {currency.currencySymbol +
                             parseFloat(
                                checkoutData.manageDetails
                                   .shippingOption
                             ).toFixed(2)} */}
                                 </td>
                                 <td width="40" align="right">${urlShipping}</td>
                              </tr>
                              <tr className="top1">
                                 <td width="20"> Taxes</td>
                                 <td width="40" align="right">
                                    {/* {" "}
                          {currency.currencySymbol +
                             parseFloat(0.0).toFixed(2)} */}
                                 </td>
                                 <td width="40" align="right">${urlTax}</td>
                              </tr>
                           </tbody>
                        </table>
                        <hr className="separator" />
                        <table width="100%">
                           <thead></thead>
                           <tbody>
                              <tr className="top1">
                                 <td width="20"> Total</td>
                                 {/* <td width="40" align="right">  <span className="price-tag"> {purchaseDetail.total} </span></td> */}
                                 <td width="40" align="right">
                                    {' '}
                                    <span className="price-tag">
                                       ${(parseInt(urlShipping) + parseInt(urlTax) + parseInt(urlTotal)).toFixed(2)}
                                    </span>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
               <div className="item-empty-area text-center">
                  {/* <div className="item-empty-area__icon mb-30"><i className="pe-7s-cash"></i></div> */}
                  <div className="item-empty-area__text">
                     {/* No items found in cart to checkout <br />{" "} */}
                     <Link
                        to={`${merchantUrlData?.decodeMerchant?.catalogURL ? merchantUrlData?.decodeMerchant?.catalogURL : '/'}`}
                        onClick={(event) => {
                           event.preventDefault();
                           //localStorage.clear();
                           window.location.href = merchantUrlData?.decodeMerchant?.catalogURL ? merchantUrlData?.decodeMerchant?.catalogURL : '/';
                        }}
                     >
                        Continue Shopping
                     </Link>
                  </div>
               </div>
            </div>
         </div>
      </>
   );
}

export default Receipt;
