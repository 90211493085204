export const ADD_USER_DETAILS = "ADD_USER_DETAILS";
export const ADD_USER_ADDRESS = "ADD_USER_ADDRESS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const ADD_MERCHANT_URL = "ADD_MERCHANT_URL";
export const ADD_MERCHANT = "ADD_MERCHANT";
export const ADD_USER_CARDS = "ADD_USER_CARDS";
export const ADD_USER_SHIP_ADDRESS = "ADD_USER_SHIP_ADDRESS";
export const USER_SHIP_ADDRESS_LIST = "USER_SHIP_ADDRESS_LIST";
export const USER_POSSIBLE_ADDRESS = "USER_POSSIBLE_ADDRESS";
export const SHIPPING_OPTIONS = "SHIPPING_OPTIONS";
export const SHIPPING_OPTIONS_LOADER = "SHIPPING_OPTIONS_LOADER";
export const CHECKOUT_SUBMIT = "CHECKOUT_SUBMIT";
export const UPDATE_ORDER_DETAIL = "UPDATE_ORDER_DETAIL";
export const ADD_CART = "ADD_CART";
export const SELECTED_SHIPPING_OPTIONS = "SELECTED_SHIPPING_OPTIONS";
export const CHECKOUT_DATA = "CHECKOUT_DATA";

// ADD USER ACTION
const addUsrDetails = (data) => (dispatch) => {
   if (data) {
      data.name = data.name?.trim();
   }
   dispatch({
      type: ADD_USER_DETAILS,
      payload: data,
   });
};

const addUsrAddress = (data) => (dispatch) => {
   dispatch({
      type: ADD_USER_ADDRESS,
      payload: data,
   });
};

const addProduct = (data) => (dispatch) => {
   dispatch({
      type: ADD_PRODUCT,
      payload: data,
   });
};

const addCart = (data) => (dispatch) => {
   dispatch({
      type: ADD_CART,
      payload: data,
   });
};

const addMerchantURL = (data) => (dispatch) => {
   dispatch({
      type: ADD_MERCHANT_URL,
      payload: data,
   });
};

const addMerchant = (data) => (dispatch) => {
   dispatch({
      type: ADD_MERCHANT,
      payload: data,
   });
};

const addUserCards = (data) => (dispatch) => {
   dispatch({
      type: ADD_USER_CARDS,
      payload: data,
   });
};

const addUserShipAddress = (data) => (dispatch) => {
   dispatch({
      type: ADD_USER_SHIP_ADDRESS,
      payload: data,
   });
};

const addUserShipAddressList = (data) => (dispatch) => {
   dispatch({
      type: USER_SHIP_ADDRESS_LIST,
      payload: data,
   });
};

const addPossibleAddress = (data) => (dispatch) => {
   dispatch({
      type: USER_POSSIBLE_ADDRESS,
      payload: data,
   });
};

const shippingOptionLoader = (data) => (dispatch) => {
   dispatch({
      type: SHIPPING_OPTIONS_LOADER,
      payload: data,
   });
};

const addShippingOption = (data) => (dispatch) => {
   dispatch({
      type: SHIPPING_OPTIONS,
      payload: data,
   });
};

const selectedShippingOption = (data) => (dispatch) => {
   dispatch({
      type: SELECTED_SHIPPING_OPTIONS,
      payload: data,
   });
};

const updateCheckoutFormValues = (data) => (dispatch) => {
   dispatch({
      type: CHECKOUT_SUBMIT,
      payload: data,
   });
};

const checkoutDataDetails = (data) => (dispatch) => {
   dispatch({
      type: CHECKOUT_DATA,
      payload: data,
   });
};

const setOrderDetail = (data) => (dispatch) => {
   dispatch({
      type: UPDATE_ORDER_DETAIL,
      payload: data,
   });
}

export {
   addUsrDetails,
   addUsrAddress,
   addProduct,
   addCart,
   addMerchantURL,
   addMerchant,
   addUserCards,
   addUserShipAddress,
   addUserShipAddressList,
   addPossibleAddress,
   shippingOptionLoader,
   addShippingOption,
   updateCheckoutFormValues,
   setOrderDetail,
   selectedShippingOption,
   checkoutDataDetails,
};